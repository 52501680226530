<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-text>
        <gmap-map
          :zoom="14"
          :center="position"
          style="width: 100%; height: 400px"
        >
          <gmap-marker
            :position="position"
            @click="center = position"
          />
        </gmap-map>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleModal()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--  -->
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisibleDialog: Boolean,
    toggleModal: {
      type: Function,
      default() {
        return void 0;
      },
    },
    position: {
      type: Object,
      default() {
        return {
          lat: 24.87847,
          lng: 67.0645625,
        };
      },
    },
  },
};
</script>
